import React, { useState, useEffect, useRef } from 'react';
import {
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  View,
  ActivityIndicator,
} from 'react-native';
import Checkbox from 'expo-checkbox';
import MultiSelect from 'react-native-multiple-select';

import { createPreMessage, updatePreMessage } from '../../../api/Marketing';
import { BasicModalContainer, ModalHeader, ModalBody, ModalFooter } from '../../../common/components/basicmodal';
import { msgStr } from '../../../common/constants/Message';
import { useAlertModal } from '../../../common/hooks';
import { commonModalStyle } from '../../../common/components/basicmodal';
import NumericInput from '../../../common/components/formcomponents/NumericInput';
import { BOHTlbRadio } from '../../../common/components/bohtoolbar';
import { TextSmallSize, TextdefaultSize } from '../../../common/constants/Fonts';
import { getBrandsData } from '../../../api/Price';

const AddPreMessageModal = ({ isModalVisible, details, setUpdatePreMessagesTrigger, closeModal }) => {
  const isUpdate = details ? true : false;

  const { showAlert } = useAlertModal();
  const [ValidMessage, setValidMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const multiSelectRef = useRef(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [inputVal, setInputVal] = useState('');

  const onSelectedItemsChange = (newSelectedItems) => {
    updateFormValues('brand_ids', newSelectedItems);
  };

  const [formValues, setFormValues] = useState<any>({
    id: null,
    before: '',
    after: '',
    before_pickup: '',
    template : null,
    active: true,
    mode: 'before',
    brand_ids: [],
  });
  
  useEffect(() => {
    getBrandsData((jsonRes) => {
      setBrands(
        jsonRes.map(item => ({
          id: item.id,
          name: item.brand  
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (isModalVisible && details) {
      setFormValues({
        id: details.id,
        before: details.before,
        after: details.after,
        before_pickup: details.after,
        template : details.template,
        active: details.active,
        mode: details.after?'after':details.before?'before':'before_pickup',
        brand_ids: details.brand_ids,
      })
      setInputVal(details.before || details.after || details.before_pickup || '')
    } else {
      setFormValues({
        id: null,
        before: '',
        after: '',
        before_pickup: '',
        template : null,
        active: true,
        mode: 'before',
        brand_ids: [],
      })
      setInputVal('')
    }
  }, [isModalVisible]);

  useEffect(()=>{
    switch(formValues.mode){
      case 'before':
        formValues.before = inputVal;
        formValues.after = '';
        formValues.before_pickup = '';
        break;
      case 'after':
        formValues.before = '';
        formValues.after = inputVal;
        formValues.before_pickup = '';
        break;
      case 'before_pickup':
        formValues.before = '';
        formValues.after = '';
        formValues.before_pickup = inputVal;
        break;
    }
  }, [inputVal, formValues.mode])

  const updateFormValues = (key, value) => {
    setFormValues(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const AddPreMessageButtonHandler = () => {
    // if(!checkInput()) return;

    setIsLoading(true);

    const handleResponse = (jsonRes, status) => {
      switch (status) {
        case 201:
          showAlert('success', jsonRes.message);
          setUpdatePreMessagesTrigger(true);
          closeModal();
          break;
        case 409:
          showAlert('error', "This address is already exist");
          break;
        default:
          if (jsonRes && jsonRes.error) showAlert('error', jsonRes.error);
          else showAlert('error', msgStr('unknownError'));
          closeModal();
          break;
      }
      setIsLoading(false);
    };

    if (isUpdate) {
      updatePreMessage(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    } else {
      createPreMessage(formValues, (jsonRes, status) => {
        handleResponse(jsonRes, status);
      });
    }
  };

  const checkInput = () => {
    if (!formValues.template || !formValues.template.trim()) {
      setValidMessage(msgStr('emptyField'));
      return false;
    } else {
      setValidMessage('');
      return true;
    }
  };

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={isModalVisible}
    >
      <BasicModalContainer>
        <ModalHeader label={isUpdate ? 'Update' : 'Add' + ' Message Schedule'} closeModal={closeModal} />
        <ModalBody>
          <View >
            <View style={{position:'absolute', flex: 1, zIndex:100, width:500 }}>
              <MultiSelect
                hideTags
                items={brands}
                uniqueKey="id"
                ref={multiSelectRef}
                onSelectedItemsChange={onSelectedItemsChange}
                selectedItems={formValues.brand_ids || []}
                selectText="Select Brands"
                searchInputPlaceholderText="Search Brands..."
                onChangeInput={(text) => console.log(text)}
                tagRemoveIconColor="#666"
                tagBorderColor="#666"
                tagTextColor="black"
                selectedItemTextColor="red"
                selectedItemIconColor="#666"
                itemTextColor="#000"
                displayKey="name"
                searchInputStyle={{ color: '#666', padding:10}}
                submitButtonColor="#666"
                submitButtonText="Select"
                styleDropdownMenuSubsection={{padding:0, margin:0, paddingLeft:10}}
                styleDropdownMenu={{margin:0, borderWidth:1, borderColor:'gray', borderStyle:'solid'}}
                styleDropdownMenuSubsection={{padding:0, border:'none', paddingLeft:10}}
                styleInputGroup={{borderWidth:1, borderColor:'gray', borderStyle:'solid'}}
                styleItemsContainer={{borderWidth:1, borderColor:'gray', borderStyle:'solid'}}
                styleTextDropdown={{fontSize:TextdefaultSize, color:'black'}}
                tagContainerStyle={{height:31, paddingTop:1}}
              />
            </View>
            <View style={{marginTop:50, marginBottom:10, width:500}}>
              {multiSelectRef.current?.getSelectedItemsExt(formValues.brand_ids || [])}
            </View>
            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
              <View>
                <BOHTlbRadio
                  label='Before Reservations'
                  onPress={val=>{
                    updateFormValues('mode', 'before')
                    updateFormValues('before', formValues.after)
                    updateFormValues('after', '')
                    updateFormValues('before_pickup', '')
                    console.log(formValues);
                  }}
                  RadioButtonProps={{
                    value: '1',
                    status: formValues.mode == 'before'?'checked':'unchecked',
                  }}
                  style={{marginLeft:0}}
                />
                <BOHTlbRadio
                  label='After Reservations'
                  onPress={val=>{
                    updateFormValues('mode', 'after')
                  }}
                  RadioButtonProps={{
                    value: '1',
                    status: formValues.mode == 'after'?'checked':'unchecked',
                  }}
                  style={{marginLeft:0}}
                />
                <BOHTlbRadio
                  label='Before pickup'
                  onPress={val=>{
                    updateFormValues('mode', 'before_pickup')
                  }}
                  RadioButtonProps={{
                    value: '1',
                    status: formValues.mode == 'before_pickup'?'checked':'unchecked',
                  }}
                  style={{marginLeft:0}}
                />
              </View>
              <View>
                <Text style={styles.label}>Days</Text>
                <NumericInput
                  style={[styles.input, {width:100}]}
                  placeholder="days"
                  value={inputVal}
                  onChangeText={val=>{
                    if(parseFloat(val)>0){
                      setInputVal(val)
                    }
                  }}
                  placeholderTextColor="#ccc"
                  onBlur={checkInput}
                  // onSubmitEditing={AddPreMessageButtonHandler}
                />
              </View>
            </View>
            <Text style={[styles.label, { marginBottom: 8 }]} selectable={true}>
              {`Message`}
            </Text>
            <TextInput
              style={[styles.input, styles.textarea, {height:180, width:500}]}
              placeholder="State"
              value={formValues.template}
              onChangeText={val=>updateFormValues('template', val)}
              placeholderTextColor="#ccc"
              multiline={true}
            />
            <Text style={[styles.label, { marginBottom: 8, color:'black', fontSize:TextSmallSize }]} selectable={true}>
              {`Available Tokens \nPlease use [customer_name], [store_name], [order_number], [start_date], \n[end_date], [start_time], [end_time]`}
            </Text>
            {/* {ValidMessage.trim() != '' && <Text style={styles.message}>{ValidMessage}</Text>} */}
            <View style={{flexDirection:'row', alignItems:'center', marginVertical:10}}>
              <Checkbox value={!formValues.active} onValueChange={() => {updateFormValues('active', !formValues.active)}} />
              <Text style={{marginLeft:10, fontSize:TextdefaultSize}}>{"Disable This Message"}</Text>
            </View>
          </View>
        </ModalBody>
        <ModalFooter>
          <TouchableOpacity onPress={AddPreMessageButtonHandler}>
            <Text style={styles.addButton}>{isUpdate ? 'Update' : 'Add'}</Text>
          </TouchableOpacity>
        </ModalFooter>
      </BasicModalContainer>
      {isLoading && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </Modal>
  );
};

const styles = commonModalStyle;

export default AddPreMessageModal;
